<template>
  <div class="location-booking-integrations">
    <ContentBlock>
      <template #instructions>
        <h1 class="f-header mb3">{{ $t('Location Reservation Settings') }}</h1>
        <p class="f-body">{{ $t('SpringboardVR supports multiple services for your customers to make reservations.') }}</p>
      </template>

      <div>
        <div class="booking-integrations">
          <UISwitch
            :title="$t('Enable SpringboardVR Reservation')"
            :value="springboardVRBookingIntegrationIndex > -1"
            @change="toggleSpringboardVRBookingIntegration"
            :instructions="$t('SpringboardVR Reservation is our first-party booking service that when enabled will allow for creating reservation widgets for this location.')"
          />
          <UISwitch
            :title="$t('Enable Checkfront Integration')"
            :value="checkfrontBookingIntegration"
            @change="toggleCheckfrontBookingIntegration"
            ref="uiSwitchCheckfront"
          >
            <template #instructions>
              <i18n path="The Checkfront Integration is for connecting {checkfrontLink} to SpringboardVR. Read the {setupInstructions}." tag="span">
                <template #checkfrontLink><a class="link-style" href="https://www.checkfront.com/" target=_blank rel=noopener>Checkfront</a></template>
                <template #setupInstructions><a class="link-style" href="https://svr.li/k/255" target=_blank rel=noopener>{{ $t('Setup Instructions') }}</a></template>
              </i18n>
            </template>
          </UISwitch>
          <div class="checkfrontsettings" v-if="checkfrontBookingIntegration">
            <UIInput
              :title="$t('Checkfront Domain')"
              :value="getCheckfrontBookingIntegrationSetting('checkfront_domain')"
              @input="setCheckfrontBookingIntegrationSetting('checkfront_domain', $event.target.value)"
              required
              rules="required"
            />
            <UIInput
              :title="$t('Checkfront API Key')"
              :value="getCheckfrontBookingIntegrationSetting('checkfront_api_key')"
              @input="setCheckfrontBookingIntegrationSetting('checkfront_api_key', $event.target.value)"
              required
              rules="required"
            />
            <UIInput
              :title="$t('Checkfront API Secret')"
              :value="getCheckfrontBookingIntegrationSetting('checkfront_api_secret')"
              @input="setCheckfrontBookingIntegrationSetting('checkfront_api_secret', $event.target.value)"
              required
              rules="required"
            />
            <!-- Causes infinite update loop -->
            <template v-if="location.experiences">
              <UIMultiselect
                v-for="experience in location.experiences"
                :detect-input="false"
                :key="'cf' + experience.id"
                :title="$t('Checkfront SKUs For') + ' ' + experience.title + ' ' + $t('Experience') "
                :taggable="true"
                :multiple="true"
                :options="[]"
                :placeholder="$t('Enter Checkfront SKU Then Hit Enter')"
                :showNoResults="false"
                :value="getCheckfrontIntegrationSKUSetting(experience.id)"
                @input="setCheckfrontIntegrationSKUSetting(experience.id, $event)"
                @tag="setCheckfrontIntegrationSKUSetting(experience.id, $event, true)"
              />
            </template>
          </div>
          <UISwitch
            :title="$t('Enable FareHarbor Integration')"
            :value="fareHarborBookingIntegration"
            @change="toggleFareHarborBookingIntegration"
          >
            <template #instructions>
              <i18n path="The Fareharbor Integration is for connecting {fareHarborLink} to SpringboardVR. Read the {setupInstructions}." tag="span">
                <template #fareHarborLink><a class="link-style" href="https://fareharbor.com/" target=_blank rel=noopener>FareHarbor</a></template>
                <template #setupInstructions><a class="link-style" href="https://svr.li/k/256" target=_blank rel=noopener>{{ $t('Setup Instructions') }}</a></template>
              </i18n>
            </template>
          </UISwitch>
          <div class="fareharborsettings" v-if="fareHarborBookingIntegration">
            <UIInput
              :title="$t('FareHarbor User Key')"
              :value="getFareHarborBookingIntegrationSetting('fareharbor_user_key')"
              @input="setFareHarborBookingIntegrationSetting('fareharbor_user_key', $event.target.value)"
              required
              rules="required"
            />
            <UIInput
              :title="$t('FareHarbor Shortname')"
              :value="getFareHarborBookingIntegrationSetting('fareharbor_shortname')"
              @input="setFareHarborBookingIntegrationSetting('fareharbor_shortname', $event.target.value)"
              required
              rules="required"
            />
            <!-- Causes infinite update loop -->
            <template class="experienceitems" v-if="location.experiences">
              <UIMultiselect
                v-for="experience in location.experiences"
                :detect-input="false"
                :key="'fh' + experience.id"
                :title="$t('FareHarbor Items For') + ' ' + experience.title + ' ' + $t('Experience') "
                :taggable="true"
                :multiple="true"
                :options="[]"
                :placeholder="$t('Enter FareHarbor Item Then Hit Enter')"
                :showNoResults="false"
                :value="getFareHarborIntegrationSKUSetting(experience.id)"
                @input="setFareHarborIntegrationSKUSetting(experience.id, $event)"
                @tag="setFareHarborIntegrationSKUSetting(experience.id, $event, true)"
              />
            </template>
          </div>
        </div>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import ContentBlock from '@/core/shared/components/ContentBlock';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIMultiselect from '@/core/shared/components/ui/UIMultiselect';
import { useSpringboardVRBooking, useCheckfrontBooking, useFareHarborBooking } from '../compositions/useBookingIntegrations';

export default {
  components: {
    ContentBlock,
    UISwitch,
    UIInput,
    UIMultiselect,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  setup (props, { emit }) {
    function updateIntegrations (integrations) {
      // this.$parent.setIntegrations(integrations);
      emit('update:integrations', integrations);
    }

    return {
      ...useSpringboardVRBooking(props, updateIntegrations),
      ...useCheckfrontBooking(props, updateIntegrations),
      ...useFareHarborBooking(props, updateIntegrations),
    };
  },
};
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.location-booking-integrations {

  & .booking-integrations {
    display: grid;
    grid-gap: var(--spacingMd);
  }

  & .checkfrontsettings,
  & .fareharborsettings {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--desktopXL) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  & >>> .multiselect__option {
    display: none;
  }
}
</style>
